import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-modal-video/scss/modal-video.scss"
import "../scss/style.default.scss"
import Footer from "./Footer"
import Header from "./Header"
import SvgIcons from "./SvgIcons"
import SEO from "./SEO"
import { config, library, dom } from "@fortawesome/fontawesome-svg-core"
import {
  faChevronDown,
  faTimes,
  faCheck,
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons"
export default function Layout({ children, customSEO }) {
  useEffect(() => {
    library.add(faChevronDown, faTimes, faCheck, faQuoteLeft)
    config.searchPseudoElements = true
    dom.watch()
  }, [])
  return (
    <React.Fragment>
      {!customSEO && <SEO />}
      <Header />
      <main>{children}</main>
      <Footer />
      <SvgIcons />
    </React.Fragment>
  )
}
