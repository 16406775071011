import React from "react"
import { Col, Container, Row } from "reactstrap"
import Logo from "../img/logo.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"

const Footer = (props) => {
  const columns = [
    {
      name: "About us",
      links: [
        {
          name: "Support center",
          link: "#",
        },
        {
          name: "Customer support",
          link: "#",
        },
        {
          name: "About us",
          link: "#",
        },
        {
          name: "Copyright",
          link: "#",
        },
        {
          name: "Popular campaign",
          link: "#",
        },
      ],
    },
    {
      name: "Our information",
      links: [
        {
          name: "Support center",
          link: "#",
        },
        {
          name: "Customer support",
          link: "#",
        },
        {
          name: "About us",
          link: "#",
        },
        {
          name: "Copyright",
          link: "#",
        },
        {
          name: "Popular campaign",
          link: "#",
        },
      ],
    },
    {
      name: "Policy",
      links: [
        {
          name: "Support center",
          link: "#",
        },
        {
          name: "Customer support",
          link: "#",
        },
        {
          name: "About us",
          link: "#",
        },
        {
          name: "Copyright",
          link: "#",
        },
        {
          name: "Popular campaign",
          link: "#",
        },
      ],
    },
  ]

  return (
    <footer className="map-bg">
      <Container className="py-5 border-bottom">
        <Row className="pt-5">
          <Col lg="3" md="6" className="mb-4 mb-lg-0">
            <img src={Logo} alt="..." width="80" className="mb-3" />
            <ul className="list-unstyled text-muted">
              <li>
                <a
                  className="reset-anchor text-small"
                  href="mailto:Iso@company.com"
                >
                  Iso@company.com
                </a>
              </li>
              <li>
                <a className="reset-anchor text-small" href="tel:+975478985">
                  +97 5478 985
                </a>
              </li>
            </ul>
            <ul className="list-inline text-muted">
              <li className="list-inline-item">
                <a className="reset-anchor" href="#">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="reset-anchor" href="#">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="reset-anchor" href="#">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
            </ul>
          </Col>
          {columns.map((column) => (
            <Col lg="3" md="6" className="mb-4 mb-lg-0" key={column.name}>
              <h4>{column.name}</h4>
              <ul className="list-unstyled text-muted mb-0">
                {column.links.map((link) => (
                  <li className="mb-1" key={link.name}>
                    <Link className="text-small reset-anchor" to={link.link}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="py-4">
        <Row className="text-center">
          <Col lg="6" className="p-lg-0 text-lg-left">
            <p className="text-muted text-small mb-0">
              © 2020 Your company, All Rights Reserved.
            </p>
          </Col>
          <Col lg="6" className="p-lg-0 text-lg-right">
            <p className="text-muted text-small mb-0">
              Template designed by{" "}
              <a
                className="text-primary reset-anchor"
                href="https://bootstraptemple.com/p/corporate"
              >
                Bootstrap Temple
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
