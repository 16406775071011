import React, { useState } from "react"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Row,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap"

const GetQuote = (props) => {
  const [modalToggle, setModalToggle] = useState(false)

  const modalToggleFunc = () => {
    setModalToggle(!modalToggle)
  }
  const formInputs = [
    {
      label: "First name",
      type: "text",
      id: "firstname",
      placeholder: "Enter your first name",
      required: true,
    },
    {
      label: "Last name",
      type: "text",
      id: "lastname",
      placeholder: "Enter your last name",
      required: true,
    },
    {
      label: "Email address",
      type: "email",
      id: "email",
      placeholder: "Enter your email address",
      required: true,
      fullwidth: true,
    },
    {
      label: "Phone number",
      type: "tel",
      id: "phone",
      placeholder: "Enter your phone number",
      optional: true,
    },
    {
      label: "Project type",
      type: "text",
      id: "projecttype",
      placeholder: "Enter your project type",
      required: true,
    },
    {
      label: "Estimated budget",
      type: "text",
      id: "budget",
      placeholder: "Enter your project type",
      required: true,
      formtext: "Project budget will be on",
    },
    {
      label: "Time frame",
      type: "text",
      id: "timeframe",
      placeholder: "Maximum time for the project",
      required: true,
    },
    {
      label: "Project details",
      type: "textarea",
      id: "projectdetail",
      placeholder: "Provide a short brief about your project",
      required: true,
      fullwidth: true,
    },
  ]
  return (
    <React.Fragment>
      <Button color="primary" onClick={modalToggleFunc}>
        Get a quote
      </Button>
      <Modal
        size="lg"
        centered
        contentClassName="p-md-3"
        isOpen={modalToggle}
        toggle={modalToggleFunc}
      >
        <ModalHeader toggle={modalToggleFunc}>
          Request a <span className="text-primary">quote</span>
        </ModalHeader>
        <ModalBody>
          <Form action="#">
            <Row>
              {formInputs.map((input) => (
                <Col lg={input.fullwidth ? 12 : 6} key={input.id}>
                  <FormGroup>
                    <Label
                      className="font-weight-bold text-small"
                      for={input.id}
                    >
                      {input.label}
                      {input.required && (
                        <span className="text-primary ml-1">&#42;</span>
                      )}
                      {input.optional && (
                        <small className="small text-gray"> optional</small>
                      )}
                    </Label>
                    <Input
                      type={input.type}
                      id={input.id}
                      placeholder={input.placeholder}
                      required={input.required}
                      rows={input.type === "textarea" ? 5 : ""}
                    />
                  </FormGroup>
                </Col>
              ))}
              <Col lg="12">
                <FormGroup>
                  <Button color="primary" type="submit">
                    Submit your request
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default GetQuote
