import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Collapse,
  Dropdown,
} from "reactstrap"

import Logo from "../img/logo.svg"

import menu from "../content/menu.json"
import GetQuote from "./GetQuote"

const Header = () => {
  const [navToggle, setNavToggle] = useState(false)
  const [dropdownToggle, setDropdownToggle] = useState(false)
  const headerRef = useRef(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [headerActive, setHeaderActive] = useState(false)

  useEffect(() => {
    let currentScrollTop = 0
    let scrollTop = 0
    const onScroll = () => {
      currentScrollTop = window.scrollY
      const headerHeight =
        headerRef && headerRef.current && headerRef.current.clientHeight

      setHideHeader(
        scrollTop < currentScrollTop &&
          currentScrollTop > headerHeight * 2 + 100
      )

      scrollTop = currentScrollTop

      setHeaderActive(currentScrollTop > 50)
    }
    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
      setHideHeader(false)
      setHeaderActive(false)
    }
  }, [headerRef])

  return (
    <header
      ref={headerRef}
      className={`header fixed-top header-animated ${
        hideHeader ? "scrollUp" : ""
      } ${headerActive ? "active" : ""}`}
    >
      <Navbar expand="lg" light className="py-3">
        <Container>
          <NavbarBrand href="/">
            <img src={Logo} alt="..." width="80" />
          </NavbarBrand>
          <NavbarToggler onClick={() => setNavToggle(!navToggle)}>
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler>
          <Collapse isOpen={navToggle} navbar>
            <Nav className="ml-auto" navbar>
              {menu.map((item) =>
                item.links ? (
                  <Dropdown
                    isOpen={dropdownToggle}
                    toggle={() => setDropdownToggle(!dropdownToggle)}
                    nav
                    inNavbar
                    key={item.name}
                  >
                    <DropdownToggle nav caret>
                      {item.name}
                    </DropdownToggle>
                    <DropdownMenu className="mt-3">
                      {item.links.map((subitem) => (
                        <DropdownItem
                          tag={Link}
                          to={subitem.link}
                          key={subitem.name}
                        >
                          {subitem.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <NavItem
                    key={item.name}
                    className={item.class ? item.class : ""}
                  >
                    <NavLink tag={Link} to={item.link}>
                      {item.name}
                    </NavLink>
                  </NavItem>
                )
              )}
              <NavItem>
                <GetQuote />
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
